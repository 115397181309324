@media screen and (max-width: 768px) {
    .garden {
        display: flex;
        flex-wrap: wrap;
    }

    .garden > div > a {
        flex: 50%;
        padding: 0em 0em;
        margin: 0em 0em;
    }

    .garden div img {
        max-height: 150px !important;
        max-width: 100%;
        width: 350px;
        object-fit: contain;
    }
}

.garden div img {
    max-height: 75px;
}